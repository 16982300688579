import React from 'react'
import ExportPDF  from './ExportPDF'
import { Button, Modal } from 'react-bootstrap'

const ExportPDFModal = ({ semestersToShow, courses, ecsfSkillSum, ecsfKnowledgeSum, supportedECSFProfiles, unSupportedECSFProfiles }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        View PDF
      </Button>

      <Modal
        show={modalShow}
        animation={false}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Preview PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body /* style={{width: '1000px'}} */>

          <ExportPDF semestersToShow={semestersToShow} courses={courses} ecsfSkillSum={ecsfSkillSum} ecsfKnowledgeSum={ecsfKnowledgeSum} supportedECSFProfiles={supportedECSFProfiles} unSupportedECSFProfiles={unSupportedECSFProfiles}/>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ExportPDFModal
