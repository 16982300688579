export const Config = {
	// appRoot      :	'/curricula-designer/',
	appRoot      :	'/',

	server       :   {
    domain        		: 'https://curricula-designer.informacni-bezpecnost.cz',
		visitorFeedback		: 'https://curricula-designer.informacni-bezpecnost.cz/data/visitorFeedback.php',
		visitorStats			: 'https://curricula-designer.informacni-bezpecnost.cz/data/visitorStats.php'
  }
}
