const ecsfProfiles = [
  { profile: "Chief Information Security Officer (Ciso)", skills: [ 0, 2, 4, 5, 7, 18, 30, 32, 39, 42, 50, 55, 57, 61, 64, 71 ], knowledges: [ 17, 18, 19, 20, 21, 23, 25, 32, 42, 55, 58] },
  { profile: "Cyber Incident Responder", skills: [ 16, 20, 60, 67, 81, 82 ], knowledges: [ 3, 5, 6, 12, 13, 21, 25, 33, 34, 35, 47, 62 ] },
  { profile: "Cyber Legal, Policy & Compliance Officer", skills: [ 13, 14, 21, 25, 43, 59, 75, 76 ], knowledges: [ 18, 21, 23, 39, 52 ] },
  { profile: "Cyber Threat Intelligence Specialist", skills: [ 10, 14, 16, 18, 20, 23, 52, 53, 62, 77 ], knowledges: [ 0, 3, 4, 9, 10, 11, 12, 13, 15, 25, 47, 56, 66 ] },
  { profile: "Cybersecurity Architect", skills: [ 12, 20, 24, 27, 28, 31, 40, 47, 69, 73 ], knowledges: [ 12, 15, 20, 22, 23, 24, 25, 26, 28, 38, 39, 53, 54, 61, 63 ] },
  { profile: "Cybersecurity Auditor", skills: [ 3, 6, 9, 17, 19, 29, 45, 65 ], knowledges: [ 1, 2, 7, 15, 23, 25, 39, 43 ] },
  { profile: "Cybersecurity Educator", skills: [ 20, 33, 37, 38, 49, 50, 51, 64, 70, 79 ], knowledges: [ 14, 15, 16, 20, 21, 23, 25, 48 ] },
  { profile: "Cybersecurity Implementer", skills: [ 8, 14, 20, 26, 35, 50, 58 ], knowledges: [ 3, 4, 15, 20, 28, 45, 47, 60, 61, 64, 65 ] },
  { profile: "Cybersecurity Researcher", skills: [ 14, 20, 28, 29, 46, 50, 63 ], knowledges: [ 23, 27, 40, 44, 56 ] },
  { profile: "Cybersecurity Risk Manager", skills: [ 1, 11, 20, 41, 56, 68 ], knowledges: [ 6, 12, 15, 22, 25, 28, 43, 57, 58, 59 ] },
  { profile: "Digital Forensics Investigator", skills: [ 15, 34, 44, 54, 80 ], knowledges: [ 3, 6, 8, 12, 13, 21, 25, 29, 30, 31, 41,47, 64 ] },
  { profile: "Penetration Tester", skills: [ 20, 22, 23, 29, 35, 48, 50, 66, 72, 74, 78 ], knowledges: [ 3, 4, 6, 13, 20, 25, 37, 46, 47, 49, 50, 51 ] },
]

export default ecsfProfiles
