import React from 'react'
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material'
import styled from 'styled-components'

import ecsfProfiles from './initStructure/ecsfProfiles'
import ecsfSkills from './initStructure/ecsfSkills'
import ecsfKnowledge from './initStructure/ecsfKnowledge'

const TabContainer = styled(TableContainer)`
  margin-top: 20px;
`;

const TabHeader = styled(TableCell)`
  background-color: #c9c9c9;
  color: #333333;
  font-weight: bold;
  padding: 12px;
  text-align: center;
`;

const TabCell = styled(TableCell)`
  padding: 12px;
  border: none;
`;

const TabRow = styled(TableRow)`
`;

const RowGrey = styled(TableRow)`
  &:nth-child(even) {
    background-color: hsl(0, 0%, 90%);;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 4px;
`;

const ListContainer = styled.div`
  column-count: 2;
  column-gap: 40px;
`;

const SectionTitle = styled.h1`
  text-align: center;
  padding: 60px 0 26px;
`;


class ExportPDF extends React.PureComponent {

  render() {

    const { courses, semestersToShow, ecsfSkillSum, ecsfKnowledgeSum, supportedECSFProfiles } = this.props

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    });

    const round = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision)

    const renderCourses = (semesterCourses) =>
      semesterCourses.map((kurz, index) => (
        <TabRow key={index}>
          <TabCell >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              {kurz.name}
            </div>
          </TabCell>
        </TabRow>
      ));

    const renderSkills = (skillIds) => (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        {skillIds.map((x, index) => (
          <span key={index} style={{ marginBottom: '5px' }}>
            &bull; {ecsfSkills[x.skillId]}
          </span>
        ))}
      </div>
    );

    const renderKnowledge = (knowledgeIds) => (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        {knowledgeIds.map((x, index) => (
          <span key={index} style={{ marginBottom: '5px' }}>
            &bull; {ecsfKnowledge[x.knowledgeId]}
          </span>
        ))}
      </div>
    );

    const calculateTotalCredits = (semesterCourses) => {
      return semesterCourses.reduce((total, course) => total + course.credits, 0);
    };    

    return (
      <div ref={this.props.forwardedRef} style={{ padding: '40px' }}>

        <div style={{ position: 'absolute', top: '2vh', right: '2vw' }}>{formattedDate}</div>

        <SectionTitle style={{paddingTop: '0'}}>Your Curriculum</SectionTitle>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TabHeader>Year</TabHeader>
                <TabHeader>Winter semester</TabHeader>
                <TabHeader>Summer semester</TabHeader>
                <TabHeader>Credits</TabHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {semestersToShow >= 1 && (
                <TabRow>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      1st
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 1 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('1-w'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 2 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('1-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 2 ? calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('1-w'))
                      ) + calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('1-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                </TabRow>
              )}
              {semestersToShow >= 3 && (
                <RowGrey>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      2nd
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 3 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('2-w'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 4 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('2-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      {semestersToShow >= 4 ? calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('2-w'))
                      ) + calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('2-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                </RowGrey>
              )}
              {semestersToShow >= 5 && (
                <TabRow>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      3rd
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 5 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('3-w'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 6 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('3-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      {semestersToShow >= 6 ? calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('3-w'))
                      ) + calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('3-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                </TabRow>
              )}
              {semestersToShow >= 7 && (
                <RowGrey>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      4th
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 7 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('4-w'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {semestersToShow >= 8 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('4-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      {semestersToShow >= 8 ? calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('4-w'))
                      ) + calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('4-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                </RowGrey>
              )}
              {semestersToShow >= 9 && (
                <TabRow>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      5th
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      {semestersToShow >= 9 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('5-w'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      {semestersToShow >= 10 ? renderCourses(
                        courses.filter((kurz) => kurz.position.includes('5-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                  <TabCell>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      {semestersToShow >= 10 ? calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('5-w'))
                      ) + calculateTotalCredits(
                        courses.filter((kurz) => kurz.position.includes('5-s'))
                      ) : '-'}
                    </div>
                  </TabCell>
                </TabRow>
              )}
              <RowGrey>
                <TabCell colSpan={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Total Credits</TabCell>
                <TabCell>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                    {courses.reduce((total, course) => total + course.credits, 0)}
                  </div>
                </TabCell>
              </RowGrey>
            </TableBody>
          </Table>
        </TableContainer>

        {/*---------------------------------------------------------------------------------------------------------------*/}
        <div style={{ pageBreakInside: 'avoid' }}>

          <SectionTitle>List of Courses</SectionTitle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TabHeader style={{ textAlign: 'left' }}>Name</TabHeader>
                  <TabHeader>Type</TabHeader>
                  <TabHeader>Semester</TabHeader>
                  <TabHeader>ETCS Credits</TabHeader>
                  <TabHeader>Training</TabHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {courses
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((course, index) => (
                    <RowGrey key={index}>
                      <TabCell>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                          {course.name}
                        </div>
                      </TabCell>
                      <TabCell>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {course.type}
                        </div>
                      </TabCell>
                      <TabCell>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {course.semester}
                        </div>
                      </TabCell>
                      <TabCell>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {course.credits}
                        </div>
                      </TabCell>
                      <TabCell>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {course.training}
                        </div>
                      </TabCell>
                    </RowGrey>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/*---------------------------------------------------------------------------------------------------------------*/}
        <div style={{ pageBreakInside: 'avoid' }}>
          <SectionTitle>ECSF Profiles</SectionTitle>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TabHeader style={{ textAlign: 'left' }}>Profile</TabHeader>
                  <TabHeader>Supported</TabHeader>
                  <TabHeader>ETCS</TabHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {ecsfProfiles.map((profile, index) => (
                  <React.Fragment key={index}>
                    {index % 2 === 0 ? (
                      <TabRow>
                        <TabCell>{profile.profile}</TabCell>
                        <TabCell>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            {supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile) ? (
                              <span style={{ fontWeight: 'bold', color: 'green' }}>Yes</span>
                            ) : (
                              <span style={{ fontWeight: 'bold', color: 'red' }}>No</span>
                            )}
                          </div>
                        </TabCell>
                        <TabCell>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            {supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile) ? round(supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile)?.credits, 2) : "-"}
                          </div>
                        </TabCell>
                      </TabRow>
                    ) : (
                      <RowGrey>
                        <TabCell>{profile.profile}</TabCell>
                        <TabCell>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            {supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile) ? (
                              <span style={{ fontWeight: 'bold', color: 'green' }}>Yes</span>
                            ) : (
                              <span style={{ fontWeight: 'bold', color: 'red' }}>No</span>
                            )}
                          </div>
                        </TabCell>
                        <TabCell>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            {supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile) ? round(supportedECSFProfiles.find(supportedProfile => supportedProfile.profile === profile.profile)?.credits, 2) : "-"}
                          </div>
                        </TabCell>
                      </RowGrey>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/*---------------------------------------------------------------------------------------------------------------*/}
        <div style={{ pageBreakInside: 'avoid' }}>
          <SectionTitle>Supported ECSF Skills</SectionTitle>
          <ListContainer>
            <List>
              {ecsfSkillSum
                .sort((a, b) => ecsfSkills[a.skillId].localeCompare(ecsfSkills[b.skillId]))
                .map((e, index) => (
                  <ListItem key={index}>{ecsfSkills[e.skillId]}</ListItem>
                ))}
            </List>
          </ListContainer>
        </div>
        {/*---------------------------------------------------------------------------------------------------------------*/}
        <div style={{ pageBreakInside: 'avoid' }}>
          <SectionTitle>Supported ECSF Knowledge</SectionTitle>
          <ListContainer>
            <List>
              {ecsfKnowledgeSum
                .sort((a, b) => ecsfKnowledge[a.knowledgeId].localeCompare(ecsfKnowledge[b.knowledgeId]))
                .map((e, index) => (
                  <ListItem key={index}>{ecsfKnowledge[e.knowledgeId]}</ListItem>
                ))}
            </List>
          </ListContainer>
        </div>
        {/*---------------------------------------------------------------------------------------------------------------*/}
        <div ref={this.props.forwardedRef} style={{ pageBreakInside: 'avoid' }}>
          <SectionTitle style={{paddingBottom: '10px', marginBottom: '-30px'}}>Courses</SectionTitle>
          {courses.map((course, index) => (
            <div key={index} style={{ margin: '46px 0 10px' }}>
              <h2 style={{ textAlign: 'left', fontSize: '24px', paddingTop: '0px' }}>
                <span style={{ backgroundColor: 'hsl(0, 0%, 90%)', padding: '6px' }}>{course.name}</span>
              </h2>

              <TabContainer>
                <Table size="small" style={{ borderCollapse: 'collapse' }}>
                  <TableHead>
                    <TabRow>
                      <TabHeader style={{ width: '50%' }}>ECSF Skills</TabHeader>
                      <TabHeader style={{ width: '50%' }}>ECSF Knowledge</TabHeader>
                    </TabRow>
                  </TableHead>

                  <TableBody>
                    <TabRow>
                      <TabCell style={{ width: '50%', verticalAlign: 'top' }}>
                        {renderSkills(course.ecsfSkillIds)}
                      </TabCell>
                      <TabCell style={{ width: '50%', verticalAlign: 'top' }}>
                        {renderKnowledge(course.ecsfKnowledgeIds)}
                      </TabCell>
                    </TabRow>
                  </TableBody>

                </Table>
              </TabContainer>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <ExportPDF {...props} forwardedRef={ref} />)
