import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import dropImg from '../assets/drop-here_moje.png'

import Course from './Course'

const CoursesList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
  text-align: center;
`

class TimeTable extends React.Component {

  exportJsonFile = () => {
    let courses = {}
    for (const property in this.props.courses) {
      courses[property] = this.props.courses[property]
    }
    const content = {
      courses: courses,
      columns: this.props.columns
    }
    const element = document.createElement("a")
    const file = new Blob([JSON.stringify(content)], { type: 'application/json' })
    element.href = URL.createObjectURL(file)
    element.download = "my-curricula.json"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  importJsonFile = (event) => {
    var file = event.target.files[0]
    let self = this
    if (event.target.files.length && file.type.match("application/json")) {
      var reader = new FileReader()
      reader.onload = function (e) {
        self.props.importData(reader.result)
      }
      reader.readAsText(file)
    }
  }

  render() {

    const printDroppable = (column) => {
      let draggedCourse = this.props.draggedCourse ? this.props.courses[this.props.draggedCourse] : null
      let isDropDisabled = draggedCourse === null ? false :
        column.semester === draggedCourse.semester ? false : true

      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot) => (
            <CoursesList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.courseIds.map((course, index) => {
                return <Course
                  key={this.props.courses[course].id}
                  course={this.props.courses[course]}
                  index={index} removeCourse={(course) => this.props.removeCourses([course])}
                  openAddModal={this.props.openAddModal}
                  columnId={column.id} />
              })}
              {provided.placeholder}
              {column.courseIds.length === 0 ? <img src={dropImg} className="dropImg" alt='dropHere' /> : ''}
            </CoursesList>
          )}
        </Droppable>
      )
    }

    return (

      <Col xs lg="6" xl="4" className="time-table">
        <h3 className="section-title"> Your Curricula</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <input type="file" id="importData" accept="application/JSON" onChange={this.importJsonFile} />
              <Button variant="outline-success" onClick={() => document.getElementById("importData").click()}>Import</Button>
              <Button variant="outline-danger" onClick={this.exportJsonFile}>Export</Button>
            </div>

            <div className="semester-selector">
              <Form.Group >
                <Form.Label>
                  Semesters:
                </Form.Label>
                <Form.Control
                  as="select"
                  custom
                  id="semesters"
                  value={this.props.semestersToShow}
                  size="sm"
                  onChange={(e) => {
                    this.props.setSemestersToShow(e.target.value)
                  }}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Form.Control>
              </Form.Group>
            </div>

            {this.props.semestersToShow >= 1 ? (
              <>
                <h5 className="main-title">1st year</h5>
                <Container>
                  <Row>
                    <Col>
                      <h6 className="semester-title">winter semester</h6>
                      {printDroppable(this.props.columns['1-w'])}
                    </Col>

                    {this.props.semestersToShow >= 2 ? (
                      <Col>
                        <h6 className="semester-title">summer semester</h6>
                        {printDroppable(this.props.columns['1-s'])}
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </>
            ) : null}

            {this.props.semestersToShow >= 3 ? (
              <>
                <h5 className="main-title second">2nd year</h5>
                <Container>
                  <Row>
                    <Col>
                      <h6 className="semester-title">winter semester</h6>
                      {printDroppable(this.props.columns['2-w'])}
                    </Col>

                    {this.props.semestersToShow >= 4 ? (
                      <Col>
                        <h6 className="semester-title">summer semester</h6>
                        {printDroppable(this.props.columns['2-s'])}
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </>
            ) : null}

            {this.props.semestersToShow >= 5 ? (
              <>
                <h5 className="main-title second">3rd year</h5>
                <Container>
                  <Row>
                    <Col>
                      <h6 className="semester-title">winter semester</h6>
                      {printDroppable(this.props.columns['3-w'])}
                    </Col>

                    {this.props.semestersToShow >= 6 ? (
                      <Col>
                        <h6 className="semester-title">summer semester</h6>
                        {printDroppable(this.props.columns['3-s'])}
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </>
            ) : null}

            {this.props.semestersToShow >= 7 ? (
              <>
                <h5 className="main-title second">4th year</h5>
                <Container>
                  <Row>
                    <Col>
                      <h6 className="semester-title">winter semester</h6>
                      {printDroppable(this.props.columns['4-w'])}
                    </Col>

                    {this.props.semestersToShow >= 8 ? (
                      <Col>
                        <h6 className="semester-title">summer semester</h6>
                        {printDroppable(this.props.columns['4-s'])}
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </>
            ) : null}

            {this.props.semestersToShow >= 9 ? (
              <>
                <h5 className="main-title second">5th year</h5>
                <Container>
                  <Row>
                    <Col>
                      <h6 className="semester-title">winter semester</h6>
                      {printDroppable(this.props.columns['5-w'])}
                    </Col>

                    {this.props.semestersToShow >= 10 ? (
                      <Col>
                        <h6 className="semester-title">summer semester</h6>
                        {printDroppable(this.props.columns['5-s'])}
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </>
            ) : null}

          </div>
        </div>

      </Col>
    )
  }
}

export default TimeTable
