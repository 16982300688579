import React from 'react'
import {Config} from '../config.js'
import Axios from 'axios'
import {Alert, Badge, Button, Col, Container, Form, Modal, Row, Spinner} from 'react-bootstrap'
import { DragDropContext } from 'react-beautiful-dnd'
import Crypto from 'crypto'
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TimeTable from './TimeTable.js'
import ListOfCourses from './ListOfCourses.js'
import Statistics from './Statistics.js'
import { cleanData, getFingerprint, checkColumnStructure, checkCourseStructure, checkSingleCourseStructure, reviseEcsf, reviseEcsfSkill, reviseEcsfKnowledge } from "./utils"
import dndStructure from './initStructure/dndStructure'
import areas from './initStructure/SpartaAreas'
import topics from './initStructure/SpartaTopics'
import sampleCourses from './initStructure/SampleCourses'
import ecsfSkills from './initStructure/ecsfSkills'
import ecsfKnowledge from './initStructure/ecsfKnowledge'

const initModalData = {
  name: '',
  id: '',
  credits: '',
  training: '',
  topics: [],
  ecsfSkillIds: [],
  ecsfKnowledgeIds: [],
  currentTopic: '',
  currentTopicPer: '',
  currentSkill: '',
  currentSkillPer: '',
  currentKnowledge: '',
  currentKnowledgePer: '',
  semester: '',
  type: '',
  nonEcsfItems : false
}

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      courses: [],
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedCourse: null,
      sampleCoursesLoaded: false,
      showAddModal: false,
      addModalValidated: false,
      addModalData: JSON.parse(JSON.stringify(initModalData)),
      modalCourseId: null,
      showFeedbackModal: false,
      fingerprint: "",
      semestersToShow: 6
    }
  }

  componentDidMount() {
 //   this.verifyVisitor()
  }

  verifyVisitor = () => {
    //create visitors fingerprint
    fetch("https://extreme-ip-lookup.com/json")
      .then(res => res.json())
      .then(ip => Promise.all([ip, getFingerprint()]))
      .then(([ip, finger]) => {
        let f = finger
          .map(({ key, value }) => ({ [key]: value }))
          .reduce((acc, curr) => ({
            ...acc,
            ...curr
          }));

        f = cleanData(f)
        ip = cleanData(ip)
        delete f.availableScreenResolution
        delete f.screenResolution

        // console.log(f)
        // console.log(ip)

        let fingerprint = Crypto.createHash('sha256').update(JSON.stringify(f) + JSON.stringify(ip)).digest('hex')

        this.setState({
          fingerprint: fingerprint
        })

        Axios.get( Config.server.visitorFeedback + "?a=" + fingerprint)
          .then((response) => {
            if (!response.data.error){

              let feedback = response.data.message
              let showModal = true
              if (feedback === "useful" || feedback === "useless") {
                showModal = false
              }
              this.setState({
                showFeedbackModal: showModal
              })

            } else {
              console.log(response.data.error)
            }
          })
          .catch((error) => {
            console.log(error)
          })
        })
  }

  closeAddModal = () => this.setState({
    showAddModal: false, modalType: null,
    addModalData: JSON.parse(JSON.stringify(initModalData)),
    addModalValidated: false,
    modalCourseId: null
  })

  closeFeedbackModal = () => this.setState({
    showFeedbackModal: false
  })

  openAddModal = (courseId, columnId) => {
    let modalData = JSON.parse(JSON.stringify(initModalData))
    if (courseId !== null) modalData = {
      ...this.state.courses[courseId],
      currentTopic: '',
      currentTopicPer: '',
      currentSkill: '',
      currentSkillPer: '',
      currentKnowledge: '',
      currentKnowledgePer: '',
      columnId: columnId
    }
    this.setState({showAddModal: true, modalCourseId: courseId, addModalData: modalData})
  }

  changeAddModal = (event) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    if (
      event.target.id === "ecsfSkillIds" ||
      event.target.id === "ecsfKnowledgeIds"
    ) {
      if (newData[event.target.id].indexOf(event.target.value - 1) === -1){
        newData[event.target.id].push(event.target.value - 1)
      }
    }
    else if (event.target.id === "credits") {
      let tmp = parseFloat(event.target.value)
      newData[event.target.id] = isNaN(tmp) ? "" : tmp
    }
    else if (
      event.target.id === "currentTopic" ||
      event.target.id === "currentSkill" ||
      event.target.id === "currentKnowledge"
    ) {
      newData[event.target.id] = event.target.value === "-1" ? "" : parseFloat(event.target.value)
    }
    else if (
      event.target.id === "currentTopicPer" ||
      event.target.id === "currentSkillPer" ||
      event.target.id === "currentKnowledgePer"
    ) {
      newData[event.target.id] = parseFloat(event.target.value)
    }
    else if (event.target.id === "nonEcsfItems") {
      newData[event.target.id] = !newData[event.target.id]
    } else {
      newData[event.target.id] = event.target.value
    }

    if (newData.name && newData.semester) {
      newData.id = newData.semester === "winter" ? "w_" : "s_"
      newData.id += newData.name.replace(/ /g,"_").toLowerCase()
    }
    if (newData.id !== this.state.modalCourseId) {
      while (typeof this.state.courses[newData.id] != "undefined"){
        newData.id += "_copy"
      }
    }
    this.setState({addModalData: newData})
  }

  submitModal = (event) =>{
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (
      form.checkValidity() === false ||

        // (data.ecsfSkillIds.length || data.ecsfKnowledgeIds.length ) &&
        // this.percentChecksum([...data.ecsfSkillIds, ...data.ecsfKnowledgeIds]) !== 100
        this.isChecksumValid() === 0

    ) {
      this.setState({addModalValidated: true})
    } else {
      if (this.state.modalCourseId === null) this.addCustomCourse(this.state.addModalData)
      else this.updateCourse(this.state.addModalData)
      this.closeAddModal()
    }
  }

  addTopic = () => {
    if (this.state.addModalData.currentTopic !== ""){
      let newData = this.state.addModalData
      newData.topics.push({
        topicId: this.state.addModalData.currentTopic,
        ectsPer: this.state.addModalData.currentTopicPer ? this.state.addModalData.currentTopicPer / 100 : 1
      })
      newData.currentTopicPer = ''
      newData.currentTopic = ''
      this.setState({addModalData: newData})
    }
  }

  removeTopic = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData.topics.splice(id, 1)
    this.setState({addModalData: newData})
  }

  editTopic = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    if (newData.currentTopic !== "") return
    newData.currentTopic = newData.topics[id].topicId
    newData.currentTopicPer = this.round(newData.topics[id].ectsPer*100,2)
    newData.topics.splice(id, 1)
    this.setState({addModalData: newData})
  }

  addSkill = () => {
    if (this.state.addModalData.currentSkill !== ""){
      let newData = this.state.addModalData
      newData.ecsfSkillIds.push({
        skillId: this.state.addModalData.currentSkill,
        ectsPer: this.state.addModalData.currentSkillPer ? this.state.addModalData.currentSkillPer / 100 : 1
      })
      newData.currentSkillPer = ''
      newData.currentSkill = ''
      this.setState({addModalData: newData})
    }
  }

  removeSkill = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData.ecsfSkillIds.splice(id, 1)
    this.setState({addModalData: newData})
  }

  editSkill = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    if (newData.currentSkill !== "") return
    newData.currentSkill = newData.ecsfSkillIds[id].skillId
    newData.currentSkillPer = this.round(newData.ecsfSkillIds[id].ectsPer*100,2)
    newData.ecsfSkillIds.splice(id, 1)
    this.setState({addModalData: newData})
  }

  addKnowledge = () => {
    if (this.state.addModalData.currentKnowledge !== ""){
      let newData = this.state.addModalData
      newData.ecsfKnowledgeIds.push({
        knowledgeId: this.state.addModalData.currentKnowledge,
        ectsPer: this.state.addModalData.currentKnowledgePer ? this.state.addModalData.currentKnowledgePer / 100 : 1
      })
      newData.currentKnowledgePer = ''
      newData.currentKnowledge = ''
      this.setState({addModalData: newData})
    }
  }

  removeKnowledge = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData.ecsfKnowledgeIds.splice(id, 1)
    this.setState({addModalData: newData})
  }

  editKnowledge = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    if (newData.currentKnowledge !== "") return
    newData.currentKnowledge = newData.ecsfKnowledgeIds[id].knowledgeId
    newData.currentKnowledgePer = this.round(newData.ecsfKnowledgeIds[id].ectsPer*100,2)
    newData.ecsfKnowledgeIds.splice(id, 1)
    this.setState({addModalData: newData})
  }

  addCustomCourse = (input) => {
    let error = false
    let newCourse = JSON.parse(JSON.stringify(input))
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    delete newCourse.currentSkill
    delete newCourse.currentSkillPer
    delete newCourse.currentKnowledge
    delete newCourse.currentKnowledgePer

    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    if (newCourse.semester === 'winter') {
      if (newCourse.type === 'mandatory') {
        newColumns['mandatory-w'].courseIds.push(newCourse.id)
      } else if (newCourse.type === 'voluntary') {
        newColumns['voluntary-w'].courseIds.push(newCourse.id)
      } else {
        console.log("Error: Wrong type!")
        error = true
      }
    } else if (newCourse.semester === 'summer'){
      if (newCourse.type === 'mandatory') {
        newColumns['mandatory-s'].courseIds.push(newCourse.id)
      } else if (newCourse.type === 'voluntary') {
        newColumns['voluntary-s'].courseIds.push(newCourse.id)
      } else {
        console.log("Error: Wrong type!")
        error = true
      }
    } else {
      console.log("Error: Wrong semester!")
      error = true
    }

    const newState = {
      ...this.state,
      columns: newColumns,
      courses: {
        ...this.state.courses,
        [newCourse.id]: newCourse
      }
    }

    if (!error) this.setState(newState)
  }

  updateCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let oldPosition = newCourse.columnId
    let newPosition = isNaN(newCourse.columnId.charAt(0)) ?
      newCourse.type + '-' + newCourse.semester.charAt(0) :
      newCourse.columnId.charAt(0) + '-' + newCourse.semester.charAt(0)
    delete newCourse.columnId
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    delete newCourse.currentSkill
    delete newCourse.currentSkillPer
    delete newCourse.currentKnowledge
    delete newCourse.currentKnowledgePer
    const newState = JSON.parse(JSON.stringify(this.state))

    // odebraní starého
    newState.columns[oldPosition].courseIds.splice( newState.columns[oldPosition].courseIds.indexOf(this.state.modalCourseId), 1 )
    delete newState.courses[this.state.modalCourseId]

    // pridani nového
    newState.courses = {...newState.courses, [newCourse.id]: newCourse}
    newState.columns[newPosition].courseIds.push(newCourse.id)

    this.setState({courses: newState.courses, columns: newState.columns})
  }

  duplicateCourse = () => {
    let newCourse = JSON.parse(JSON.stringify(this.state.courses[this.state.modalCourseId]))
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newPosition = null
    Object.values(this.state.columns).some(item => {
      if(item.courseIds.includes(newCourse.id)){
        newPosition = item.id
        return true
      } else { return null }
    })
    newCourse.name += " (copy)"
    newCourse.id += "_copy"
    newColumns[newPosition].courseIds.push(newCourse.id)

    this.setState({
      courses: {...this.state.courses, [newCourse.id]: newCourse},
      columns: newColumns
    })
    this.closeAddModal()
  }

  removeForeverCourse = (course) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[course.position].courseIds.splice( newState.columns[course.position].courseIds.indexOf(course.id), 1 )
    delete newState.courses[course.id]
    this.setState({columns: newState.columns, courses: newState.courses})
  }

  removeCourses = (courses) => {
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    courses.forEach((course, i) => {
      let newPosition = course.type + '-' + course.semester.charAt(0)
      let index = newColumns[course.position].courseIds.indexOf(course.id)
      newColumns[course.position].courseIds.splice(index , 1)
      newColumns[newPosition].courseIds.push(course.id)
    })
    this.setState({columns: newColumns})
  }

  importCourses = (input) => {
    const newData = JSON.parse(input)
    if (!newData.hasOwnProperty("single_course") && !newData.hasOwnProperty("available_courses")) {
      this.setState({error:{message: "Wrong input file format!"}})
    } else {

      let input = newData.hasOwnProperty("single_course") ? [newData.single_course] : newData.available_courses
      let newState = {
        courses: JSON.parse(JSON.stringify(this.state.courses)),
        columns: JSON.parse(JSON.stringify(this.state.columns))
      }

      input.forEach((inputCourse, i) => {
        if (!checkSingleCourseStructure(inputCourse)) {
          this.setState({error:{message: "Some course was not imported due to invalid format!"}})
          console.log("import error: " + (inputCourse.hasOwnProperty("id") ? inputCourse.id : "unknown id"))
        } else {
          // check for id duplicates
          let currentCourses = Object.keys(this.state.courses)
          let revisedId = inputCourse.id
          if (currentCourses.includes(revisedId)) {
            var counter = 2
            while (currentCourses.includes(inputCourse.id+"_"+counter)) {
              counter++
            }
            revisedId = inputCourse.id+"_"+counter
          }
          newState.courses = {
            ...newState.courses,
            [revisedId]: {
              ...inputCourse,
              id: revisedId
            }
          }
          // identify new course position
          let newPosition = (inputCourse.type === 'mandatory' ? 'mandatory' : 'voluntary') + '-' + (inputCourse.semester === 'winter' ? 'w' : 's')
          newState.columns[newPosition].courseIds.push(revisedId)
        }
      })

      // write to state
      this.setState({columns: newState.columns, courses: newState.courses})
    }
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)

      // lower number of semesters compatibility correction
      if (!newData.hasOwnProperty("columns")) {
        this.setState({error:{message: "Wrong input file format!"}})
        return
      } else {
        if (!newData.columns.hasOwnProperty('4-w')) newData.columns["4-w"] = {id: '4-w', semester: 'winter', year: "4", title: '4 year winter semester', courseIds: []}
        if (!newData.columns.hasOwnProperty('4-s')) newData.columns["4-s"] = {id: '4-s', semester: 'summer', year: "4", title: '4 year summer semester', courseIds: []}
        if (!newData.columns.hasOwnProperty('5-w')) newData.columns["5-w"] = {id: '5-w', semester: 'winter', year: "5", title: '5 year winter semester', courseIds: []}
        if (!newData.columns.hasOwnProperty('5-s')) newData.columns["5-s"] = {id: '5-s', semester: 'summer', year: "5", title: '5 year summer semester', courseIds: []}
      }

      // input data structure validation test
      let columnTest = checkColumnStructure(newData.columns, dndStructure.columns)
      let courseTest = checkCourseStructure(newData.courses, newData.columns)

      // ecsf old version compatibility correction
      let inputCourses = Object.entries(newData.courses).map(([k, v] ) => (v))
      reviseEcsf(inputCourses)
      reviseEcsfSkill(inputCourses)
      reviseEcsfKnowledge(inputCourses)

      if (columnTest && courseTest) {
        // option 1 - overwrite the current state
        // this.setState({
        //   courses: newData.courses,
        //   columns: newData.columns
        // })

        // option 2 - add to the current courses
        let newCourses = []
        let currentCourses = Object.keys(this.state.courses)
        Object.keys(newData.columns).forEach(column => {
          newData.columns[column].courseIds.forEach(newCourseId => {
            let revisedId = newCourseId
            if (currentCourses.includes(revisedId) || newCourses.find(course => course.revisedId === revisedId)) {
              var counter = 2
              var tmp = function(c){
                return newCourses.find(course => course.revisedId === newCourseId+"_"+c)
              }
              while (currentCourses.includes(newCourseId+"_"+counter) || tmp(counter)) {
                counter++
              }
              revisedId = newCourseId+"_"+counter
            }
            newCourses.push({courseId: newCourseId, revisedId: revisedId, position: column})
          })
        })

        // prepare of writing to the state
        let newState = {
          courses: JSON.parse(JSON.stringify(this.state.courses)),
          columns: JSON.parse(JSON.stringify(this.state.columns))
        }
        newCourses.forEach(newCourse => {
          newState.courses = {
            ...newState.courses,
            [newCourse.revisedId]: {
              ...newData.courses[newCourse.courseId],
              id: newCourse.revisedId
            }
          }
          newState.columns[newCourse.position].courseIds.push(newCourse.revisedId)
        })

        // expand timetable if imported courses are located in hidden semesters
        let requiredYears = Math.ceil(this.state.semestersToShow / 2)
        for (let i = 1; i <= 5; i++) {
          if ((newState.columns[i+"-w"].courseIds.length || newState.columns[i+"-s"].courseIds.length) && i > requiredYears) requiredYears = i
        }
        // write to the state
        this.setState({columns: newState.columns, courses: newState.courses, semestersToShow: requiredYears*2})
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch(e) {
      console.log(e)
      this.setState({error:{message: "Sorry, something went wrong!"}})
    }
  }

  exportCourse = () => {
    let data = JSON.parse(JSON.stringify(this.state.addModalData))
    delete data.currentSkill
    delete data.currentSkillPer
    delete data.currentKnowledge
    delete data.currentKnowledgePer
    delete data.currentTopic
    delete data.currentTopicPer
    delete data.columnId

    let content = {
      single_course: data
    }

    const element = document.createElement("a")
    const file = new Blob([JSON.stringify(content)], { type: 'application/json' })
    element.href = URL.createObjectURL(file)
    element.download = data.id+".json"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  sendFeedback = (feedback) => {
    this.closeFeedbackModal()
    Axios.get( Config.server.visitorFeedback + "?a=" + this.state.fingerprint + "&b=" + feedback )
      .catch((error) => {
        console.log(error)
      })
  }

  fetchData = () => {
    if (typeof(sampleCourses) !== "undefined" && sampleCourses !== null && sampleCourses.length !== 0){
      let sortedCourses = sampleCourses.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      let allCourses = []
      sortedCourses.forEach(course => {
        let newId = course.semester === "winter" ? "w_" : "s_"
        newId += course.name.replace(/ /g,"_").toLowerCase()
        if (!this.state.courses.hasOwnProperty(newId)) {
          course.id = newId
          allCourses[course.id] = course
        }
      })

      let newState = {
        sampleCoursesLoaded: true
      }

      if (Object.keys(allCourses).length !== 0) {
        let newCols = JSON.parse(JSON.stringify(this.state.columns))
        newCols['mandatory-w'].courseIds = [...newCols['mandatory-w'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "mandatory" && course.semester === "winter"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['mandatory-s'].courseIds = [...newCols['mandatory-s'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "mandatory" && course.semester === "summer"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['voluntary-w'].courseIds = [...newCols['voluntary-w'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "voluntary" && course.semester === "winter"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['voluntary-s'].courseIds = [...newCols['voluntary-s'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "voluntary" && course.semester === "summer"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]

        newState = {
          ...newState,
          courses: {...JSON.parse(JSON.stringify(this.state.courses)), ...allCourses},
          columns: newCols
        }
      }

      this.setState(newState)
    } else {
      this.setState({error:{message: "There are no courses in the database!"}})
    }
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedCourse = this.state.courses[start.draggableId].id

    this.setState({
      homeIndex,
      draggedCourse,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }

    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]

    if (start === finish) {
      const newCourseIds = Array.from(start.courseIds)
      newCourseIds.splice(source.index, 1)
      newCourseIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        courseIds: newCourseIds
      }

      const newState = {
        ...this.state,
        homeIndex: null,
        draggedCourse: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }

      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startCourseIds = Array.from(start.courseIds)
    startCourseIds.splice(source.index, 1)
    const newStart = {
      ...start,
      courseIds: startCourseIds
    }

    const finishCourseIds = Array.from(finish.courseIds)
    finishCourseIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      courseIds: finishCourseIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedCourse: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  round = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision)

  percentChecksum = (array) => this.round((Object.keys(array).reduce((sum,key)=>sum+parseFloat(array[key].ectsPer||0),0))*100,2)

  isChecksumValid = () => {
    if ([...this.state.addModalData.ecsfKnowledgeIds, ...this.state.addModalData.ecsfSkillIds].length) {
      let checksum = this.percentChecksum([...this.state.addModalData.ecsfKnowledgeIds, ...this.state.addModalData.ecsfSkillIds])
      if (checksum > 100) {
        return 0
      } else if (this.state.addModalData.nonEcsfItems) {
        return 1
      } else if (checksum === 100) {
        return 2
      } else {
        return 0
      }
    } else {
      return 1
    }
  }

  setSemestersToShow = (newValue) => {
    let coursesToHide = []; // zde musí zůstat středník, jinak to háže chybu
    ["1-w", "1-s", "2-w", "2-s", "3-w", "3-s", "4-w", "4-s", "5-w", "5-s"].forEach((position, i) => {
      if (newValue < i+1) {
        let courseIds = JSON.parse(JSON.stringify(this.state.columns[position].courseIds))
        courseIds.forEach(courseId => {
          coursesToHide.push({...this.state.courses[courseId], position: position})
        })
      }
    })
    this.removeCourses(coursesToHide)
    this.setState({semestersToShow: parseInt(newValue)})
  }


  render(){

    const { isLoading, columns, error, courses, addModalData, draggedCourse, showFeedbackModal, showAddModal, addModalValidated, modalCourseId, sampleCoursesLoaded, semestersToShow } = this.state

    const cutLongString = (longString, newLength) => {
      if (longString.length > newLength) {
        return longString.substr(0, newLength) + "..."
      } else {
        return longString
      }
    }

    // skryva již vybrané topicy ze select nabidky
    const reducedTopics = () => {
      let output = topics.map((item,idx) => {return {...item, index: idx}}).filter((topic, index) => {
        return addModalData.topics.find(o => o.topicId === index) === undefined
      })
      return output
      // return topics
    }

    const reducedSkills = () => {
      let output = ecsfSkills.map((item,idx) => ({name: item, index: idx})).filter(skill =>  addModalData.ecsfSkillIds.find(o => o.skillId === skill.index) === undefined && skill.name !== "")
      return output
      // return topics
    }

    const reducedKnowledge = () => {
      let output = ecsfKnowledge.map((item,idx) => {return {name: item, index: idx}}).filter((knowledge, index) => {
        return addModalData.ecsfKnowledgeIds.find(o => o.knowledgeId === index) === undefined
      })
      return output
      // return topics
    }

    let activeCoursesSum =
      columns["1-w"].courseIds.length +
      columns["1-s"].courseIds.length +
      columns["2-w"].courseIds.length +
      columns["2-s"].courseIds.length +
      columns["3-w"].courseIds.length +
      columns["3-s"].courseIds.length

    let remainingPercent = this.round(100 -this.percentChecksum([...addModalData.ecsfKnowledgeIds, ...addModalData.ecsfSkillIds]),2)

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <div><Button variant="link" onClick={()=>this.setState({error:null})}>Go back</Button></div>
          </div>
        ) : (
          isLoading ? (
            <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading</div>
          ) : (
            <Container fluid>
              <Row>
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                  <ListOfCourses columns={columns} courses={courses}
                    draggedCourse={draggedCourse}
                    sampleCoursesLoaded={sampleCoursesLoaded}
                    addCustomCourse={this.addCustomCourse}
                    removeCourse={this.removeForeverCourse}
                    fetchData={this.fetchData}
                    openAddModal={this.openAddModal}
                    importCourses={this.importCourses}
                  />
                  <TimeTable columns={columns} courses={courses}
                    draggedCourse={draggedCourse}
                    removeCourses={this.removeCourses}
                    importData={this.importData}
                    openAddModal={this.openAddModal}
                    semestersToShow={semestersToShow}
                    setSemestersToShow={this.setSemestersToShow}
                  />
                  <Statistics columns={columns} courses={courses} semestersToShow={semestersToShow}/>
                </DragDropContext>
              </Row>
            </Container>
          )
        )}

        <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

          <Form noValidate validated={addModalValidated} onSubmit={this.submitModal}>

            <Modal.Header closeButton className={modalCourseId === null ? "" : " edit"}>
              <Modal.Title>{modalCourseId === null ? "Add new course" : "Edit course"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ID</b>
                </Form.Label>
                <Col sm={8} className="vertical">
                  <Form.Label id="id">{addModalData.id}</Form.Label>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Name</b>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" id="name" required onChange={this.changeAddModal} value={addModalData.name}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="type" >
                <Form.Label column sm={3}>
                  <b>Type</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Mandatory"
                    name="type"
                    value="mandatory"
                    checked={addModalData.type === 'mandatory'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Voluntary"
                    name="type"
                    value="voluntary"
                    checked={addModalData.type === 'voluntary'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="semester" >
                <Form.Label column sm={3}>
                  <b>Semester</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Winter"
                    name="semester"
                    value="winter"
                    checked={addModalData.semester === 'winter'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Summer"
                    name="semester"
                    value="summer"
                    checked={addModalData.semester === 'summer'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="training" >
                <Form.Label column sm={3}>
                  <b>Training</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="training"
                    value='Yes'
                    checked={addModalData.training === 'Yes'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="No"
                    name="training"
                    value='No'
                    checked={addModalData.training === 'No'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECTS Credits</b>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="number" id="credits" required
                  onChange={this.changeAddModal} value={addModalData.credits} min="0"/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Topics (ECTS %)</b>
                </Form.Label>
                <Col sm={4} className="vertical">
                  <Form.Control as="select" id="currentTopic"
                  onChange={this.changeAddModal} value={addModalData.currentTopic}>
                    <option value="-1"></option>
                    {reducedTopics().map((topic, index) => {
                      return <option key={index} style={{background: topic? areas[topic.areaId].color : '', color: 'white'}} value={topic.index}>{topic.name}</option>
                    })}
                  </Form.Control>
                </Col>
                <Col sm={2} className="vertical percents">
                  {addModalData.currentTopic !== "" ? <><Form.Control type="number" id="currentTopicPer"
                  onChange={this.changeAddModal} value={addModalData.currentTopicPer ? addModalData.currentTopicPer : 100} min="0" max="100" step="0.01"/>&nbsp;%</> : ""}
                </Col>
                <Col sm={2} className="vertical">
                  {addModalData.currentTopic !== "" ? <Button variant="outline-success" size="sm" disabled={addModalData.currentTopic === ""} onClick={this.addTopic}>Save</Button> : ""}
                </Col>
              </Form.Group>

              {addModalData.topics.length ? <>
                <Form.Group as={Row} >
                  <Col sm={3} ></Col>
                  <Col sm={8} >
                    <table><tbody>
                    {addModalData.topics.map((topic, index) => {
                      return (
                        <tr key={index}>
                          <td className="topicListPer" >{this.round(topic.ectsPer*100,2)}&nbsp;%&nbsp;</td>
                          <td><div className="modal-badge" style={{background: areas[topics[topic.topicId].areaId].color}}>{topics[topic.topicId].name}</div></td>
                          <td><EditIcon color="action" className={addModalData.currentTopic === "" ? "editIcon" : "editIcon disabled"} fontSize="small" title="Edit topic percentage" onClick={()=>this.editTopic(index)}/></td>
                          <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove topic" onClick={()=>this.removeTopic(index)}/></td>
                        </tr>
                      )
                    })}
                    </tbody></table>
                  </Col>
                </Form.Group>
              </>: null }

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECSF Skills</b>
                </Form.Label>
                <Col sm={4} className="vertical">
                  <Form.Control as="select" id="currentSkill"
                  onChange={this.changeAddModal} value={addModalData.currentSkill}>
                    <option value="-1"></option>
                    {reducedSkills().map((skill, index) => {
                      return <option key={index} value={skill.index}>{cutLongString(skill.name,100)}</option>
                    })}
                  </Form.Control>
                </Col>
                <Col sm={2} className="vertical percents">
                  {addModalData.currentSkill !== "" ? <><Form.Control type="number" id="currentSkillPer"
                  onChange={this.changeAddModal} value={addModalData.currentSkillPer ? addModalData.currentSkillPer : 100} min="0" max="100" step="0.01"/>&nbsp;%</> : ""}
                </Col>
                <Col sm={2} className="vertical">
                  {addModalData.currentSkill !== "" ? <Button variant="outline-success" size="sm" disabled={addModalData.currentSkill === ""} onClick={this.addSkill}>Save</Button> : ""}
                </Col>
              </Form.Group>

              {addModalData.ecsfSkillIds.length ?
                <Form.Group as={Row} >
                  <Col sm={3} ></Col>
                  <Col sm={8} >
                    <table><tbody>
                      {addModalData.ecsfSkillIds.map((skill, index) => {
                        return (
                          <tr key={index}>
                            <td className="topicListPer" >{this.round(skill.ectsPer*100,2)}&nbsp;%&nbsp;</td>
                            <td><div className="modal-badge ecsf">{ecsfSkills[skill.skillId]}</div></td>
                            <td><EditIcon color="action" className={addModalData.currentSkill === "" ? "editIcon" : "editIcon disabled"} fontSize="small" title="Edit skill percentage" onClick={()=>this.editSkill(index)}/></td>
                            <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove skill" onClick={()=>this.removeSkill(index)}/></td>
                          </tr>
                        )
                      })}
                    </tbody></table>
                  </Col>
                </Form.Group>
              : null }

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECSF Knowledge</b>
                </Form.Label>
                <Col sm={4} className="vertical">
                  <Form.Control as="select" id="currentKnowledge"
                  onChange={this.changeAddModal} value={addModalData.currentKnowledge}>
                    <option value="-1"></option>
                    {reducedKnowledge().map((knowledge, index) => {
                      return <option key={index} value={knowledge.index}>{cutLongString(knowledge.name,100)}</option>
                    })}
                  </Form.Control>
                </Col>
                <Col sm={2} className="vertical percents">
                  {addModalData.currentKnowledge !== "" ? <><Form.Control type="number" id="currentKnowledgePer"
                  onChange={this.changeAddModal} value={addModalData.currentKnowledgePer ? addModalData.currentKnowledgePer : 100} min="0" max="100" step="0.01"/>&nbsp;%</> : ""}
                </Col>
                <Col sm={2} className="vertical">
                  {addModalData.currentKnowledge !== "" ? <Button variant="outline-success" size="sm" disabled={addModalData.currentKnowledge === ""} onClick={this.addKnowledge}>Save</Button> : ""}
                </Col>
              </Form.Group>

              {addModalData.ecsfKnowledgeIds.length ?
                <Form.Group as={Row} >
                  <Col sm={3} ></Col>
                  <Col sm={8} >
                    <table><tbody>
                      {addModalData.ecsfKnowledgeIds.map((knowledge, index) => {
                        return (
                          <tr key={index}>
                            <td className="topicListPer">{this.round(knowledge.ectsPer*100,2)}&nbsp;%&nbsp;</td>
                            <td><div className="modal-badge ecsf">{ecsfKnowledge[knowledge.knowledgeId]}</div></td>
                            <td><EditIcon color="action" className={addModalData.currentKnowledge === "" ? "editIcon" : "editIcon disabled"} fontSize="small" title="Edit knowledge percentage" onClick={()=>this.editKnowledge(index)}/></td>
                            <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove knowledge" onClick={()=>this.removeKnowledge(index)}/></td>
                          </tr>
                        )
                      })}
                    </tbody></table>
                  </Col>
                </Form.Group>
              : null }

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Non-ECSF Skill/Knowledge</b>
                </Form.Label>
                <Col sm={8} className="vertical">
                <span className="topicListPer">{remainingPercent < 0 ? 0 : remainingPercent}&nbsp;%</span>
                  <Form.Check type="checkbox" label={""} id="nonEcsfItems"
                  onChange={this.changeAddModal} checked={addModalData.nonEcsfItems} />
                </Col>
              </Form.Group>

              {addModalData.ecsfSkillIds.length || addModalData.ecsfKnowledgeIds.length ?
                <Form.Group as={Row} className="percent-checksum">
                  <Col sm={3}></Col>
                  <Col sm={8}>
                    <Form.Text className="text-muted" >
                      <span className={
                        addModalValidated && this.isChecksumValid() === 0 ? "invalid" : ""
                      }>
                      Study Workload: <Badge variant={this.isChecksumValid() === 0 ? 'danger' : this.isChecksumValid() === 2 ? 'success' : 'secondary'}>{
                        addModalData.nonEcsfItems ? (
                          remainingPercent < 0 ? this.percentChecksum([...addModalData.ecsfKnowledgeIds, ...addModalData.ecsfSkillIds]) : 100
                        ) : (
                          this.percentChecksum([...addModalData.ecsfKnowledgeIds, ...addModalData.ecsfSkillIds])
                        )
                      } %</Badge>
                      </span>
                    </Form.Text>
                  </Col>
                </Form.Group>
              : null}

            </Modal.Body>
            <Modal.Footer>
              {modalCourseId === null ? "" : <>
                <Button variant="outline-dark" onClick={this.exportCourse}>Export</Button>
                <Button variant="outline-dark" className="left" onClick={this.duplicateCourse}>Duplicate</Button>
              </>}
              <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form>
        </Modal>


        <Modal show={showFeedbackModal && activeCoursesSum > 4} onHide={this.closeFeedbackModal} backdrop="static" animation={false} centered className="modal-feedback">
          <Modal.Header closeButton>
            <Modal.Title>
              Please provide us with feedback
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Was the application useful for designing new curricula?
            </h5>
            <p>
              <Button variant="success" onClick={()=>this.sendFeedback(true)}>YES</Button>
              <Button variant="danger" onClick={()=>this.sendFeedback(false)}>NO</Button>
            </p>
          </Modal.Body>
        </Modal>

      </div>
    )
  }

}

export default App;
