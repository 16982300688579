import fp from "fingerprintjs2"
import ecsfSkills from './initStructure/ecsfSkills'
import ecsfKnowledge from './initStructure/ecsfKnowledge'

export const cleanData = f => {
  for (let key in f) {
    if (f[key] === null || f[key] === undefined || f[key] instanceof Error) {
      delete f[key]
    }
    if (Array.isArray(f[key])) {
      f[key] = f[key].join(", ")
    }
    if (
      (typeof f[key] === "string" || f[key] instanceof String) &&
      f[key].length === 0
    ) {
      delete f[key]
    }
    if (typeof f[key] === "boolean") {
      f[key] = `${f[key]}`
    }
  }
  return f
}


export const getFingerprint = () =>
  new Promise(resolve => {
    fp.get(components => {
      resolve(components)
    })
  })


export const checkColumnStructure = (object1, object2, keyI) => {

  const isObject = (object) => {
    return object != null && typeof object === 'object'
  }

  const checkColumnStructureFunction = (o1,o2,keyI) =>{

    if (keyI === "courseIds") return true
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length || keys1.toString() !== keys2.toString()) {
      console.log(keyI)
      console.log(keys1)
      console.log(keys2)
      return false
    }

    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = isObject(val1) && isObject(val2)
      // if (areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
      if (areObjects && !checkColumnStructure(val1, val2, key)) {
        return false
      }
    }
    return true;
  }

  return checkColumnStructureFunction(object1, object2, keyI)
}


const arrayComparison = (arrA, arrB) => {
  // compare length and if all 1st array contains all 2nd array elements
  let test1 = arrA.every(a => arrB.some(b => a === b))
  let test2 = arrA.length === arrB.length
  return test1===true && test2===true
}

const round = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision)

// const percentChecksum = (array) => round((Object.keys(array).reduce((sum,key)=>sum+parseFloat(array[key].ectsPer||0),0))*100,2)


export const checkCourseStructure = (courses, columns) => {

  let inputCourses = [
    ...columns['custom-w'].courseIds,
    ...columns['custom-s'].courseIds,
    ...columns['mandatory-w'].courseIds,
    ...columns['mandatory-s'].courseIds,
    ...columns['voluntary-w'].courseIds,
    ...columns['voluntary-s'].courseIds,
    ...columns['1-w'].courseIds,
    ...columns['1-s'].courseIds,
    ...columns['2-w'].courseIds,
    ...columns['2-s'].courseIds,
    ...columns['3-w'].courseIds,
    ...columns['3-s'].courseIds,
    ...columns['4-w'].courseIds,
    ...columns['4-s'].courseIds,
    ...columns['5-w'].courseIds,
    ...columns['5-s'].courseIds
  ]

  // test 1 - columns contains all courses
  if (!arrayComparison(Object.keys(courses), inputCourses)) return false

  // test 2 - valid courses structure
  inputCourses.forEach((courseId) => {
     if (!checkSingleCourseStructure(courses[courseId])) return false
  })
  return true
}

export const checkSingleCourseStructure = (course) => {
  let emptyCourse = {id: "", name: "", semester: "", training: "", type: "", credits: "", nonEcsfItems: false, topics: [{ectsPer: null, topicId: null}], ecsfKnowledgeIds: [{ectsPer: null, knowledgeId: null}], ecsfSkillIds: [{ectsPer: null, skillId: null}]}
  if (!arrayComparison(Object.keys(course),Object.keys(emptyCourse))) return false
  course.topics.forEach((topic)=>{
    if (!arrayComparison(Object.keys(topic),Object.keys(emptyCourse.topics[0]))) return false
  })
  course.ecsfKnowledgeIds.forEach((knowledge)=>{
    if (!arrayComparison(Object.keys(knowledge),Object.keys(emptyCourse.ecsfKnowledgeIds[0]))) return false
  })
  course.ecsfSkillIds.forEach((skill)=>{
    if (!arrayComparison(Object.keys(skill),Object.keys(emptyCourse.ecsfSkillIds[0]))) return false
  })
  return true
}


export const reviseEcsf = (inputCourses) => {
  return inputCourses.map(course => {
    if (course.nonEcsfItems === undefined) {
      course.nonEcsfItems = false
    }
    return course
  })
}


export const reviseEcsfSkill = (inputCourses) => {
  // test 4 - valid course ecsf skill dndStructure
  let skillStructure = {skillId: null, ectsPer: null}
  let newCourses = inputCourses.map(course => {
    if (
      course.ecsfSkillIds === undefined ||
      !Array.isArray(course.ecsfSkillIds) ||
      course.ecsfSkillIds.length === 0
    ) {
      course.ecsfSkillIds = []
    } else {
      let newSkill = []
      course.ecsfSkillIds.forEach(skill => {
        if (
          arrayComparison(Object.keys(skill),Object.keys(skillStructure)) &&
          ecsfSkills[skill.skillId] !== undefined
        ) {
          // ok stav -prevezme se to z import dat
          newSkill.push(skill)
        } else if (
          !isNaN(parseInt(skill)) &&
          ecsfSkills[skill] !== undefined
        ) {
          newSkill.push({skillId: skill, ectsPer: round(1/[...course.ecsfKnowledgeIds, ...course.ecsfSkillIds].length,4)})
        }
      })
      // let checksum = percentChecksum(newSkill)
      // if (checksum !== 100.00) {
      //   let diff = 100.00 - checksum
      //   newSkill[0].ectsPer = round(newSkill[0].ectsPer + (diff/100),4)
      // }
      course.ecsfSkillIds = newSkill
    }
    return course
  })
  return newCourses
}


export const reviseEcsfKnowledge = (inputCourses) => {
  // test 4 - valid course ecsf knowledge dndStructure
  let knowledgeStructure = {knowledgeId: null, ectsPer: null}
  let newCourses = inputCourses.map(course => {
    if (
      course.ecsfKnowledgeIds === undefined ||
      !Array.isArray(course.ecsfKnowledgeIds) ||
      course.ecsfKnowledgeIds.length === 0
    ) {
      course.ecsfKnowledgeIds = []
    } else {
      let newKnowledge = []
      course.ecsfKnowledgeIds.forEach(knowledge => {
        if (
          arrayComparison(Object.keys(knowledge),Object.keys(knowledgeStructure)) &&
          ecsfKnowledge[knowledge.knowledgeId] !== undefined
        ) {
          // ok stav -prevezme se to z import dat
          newKnowledge.push(knowledge)
        } else if (
          !isNaN(parseInt(knowledge)) &&
          ecsfKnowledge[knowledge] !== undefined
        ) {
          newKnowledge.push({knowledgeId: knowledge, ectsPer: round(1/[...course.ecsfKnowledgeIds, ...course.ecsfSkillIds].length,4)})
        }
      })
      // let checksum = percentChecksum(newKnowledge)
      // if (checksum !== 100.00) {
      //   let diff = 100.00 - checksum
      //   newKnowledge[0].ectsPer = round(newKnowledge[0].ectsPer + (diff/100),4)
      // }
      course.ecsfKnowledgeIds = newKnowledge
    }
    return course
  })
  return newCourses
}
