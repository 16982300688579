const ecsfSkills = [
  "Analyse and comply with cybersecurity-related laws, regulations and legislations",
	"Analyse and consolidate organisation’s quality and risk management practices",
	"Analyse and implement cybersecurity policies, certifications, standards, methodologies and frameworks",
	"Analyse business processes, assess and review software or hardware security, as well as technical and organisational controls",
	"Anticipate cybersecurity threats, needs and upcoming challenges",
	"Anticipate required changes to the organisation’s information security strategy and formulate new plans",
	"Apply auditing tools and techniques",
	"Assess and enhance an organisation’s cybersecurity posture",
	"Assess the security and performance of solutions",
	"Audit with integrity, being impartial and independent",
	"Automate threat intelligence management procedures",
	"Build a cybersecurity risk-aware environment",
	"Build resilience against points of failure across the architecture",
	"Carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
	"Collaborate with other team members and colleagues",
	"Collect information while preserving its integrity",
	"Collect, analyse and correlate cyber threat information originating from multiple sources",
	"Collect, evaluate, maintain and protect auditing information",
	"Communicate, coordinate and cooperate with internal and external stakeholders",
	"Communicate, explain and adapt legal and regulatory requirements and business needs",
	"Communicate, present and report to relevant stakeholders",
	"Comprehensive understanding of the business strategy, models and products and ability to factor into legal, regulatory and standards’ requirements",
	"Conduct ethical hacking",
	"Conduct technical analysis and reporting",
	"Conduct user and business security requirements analysis",
	"Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools",
	"Configure solutions according to the organisation’s security policy",
	"Coordinate the integration of security solutions",
	"Decompose and analyse systems to develop security and privacy requirements and identify effective solutions",
	"Decompose and analyse systems to identify weaknesses and ineffective controls",
	"Define and apply maturity models for cybersecurity management",
	"Design systems and architectures based on security and privacy by design and by defaults cybersecurity principles",
	"Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing",
	"Design, develop and deliver learning programmes to cover cybersecurity needs",
	"Develop and communicate, detailed and reasoned investigation reports",
	"Develop code, scripts and programmes",
	"",
	"Develop cybersecurity exercises including simulations using cyber range environments",
	"Develop evaluation programs for the awareness, training and education activities",
	"Develop, champion and lead the execution of a cybersecurity strategy",
	"Draw cybersecurity architectural and functional specifications",
	"Enable business assets owners, executives and other stakeholders to make risk-informed decisions to manage and mitigate risks",
	"Establish a cybersecurity plan",
	"Explain and communicate data protection and privacy topics to stakeholders and users",
	"Explain and present digital evidence in a simple, straightforward and easy to understand way",
	"Follow and practice auditing frameworks, standards and methodologies",
	"Generate new ideas and transfer theory into practice",
	"Guide and communicate with implementers and IT/OT personnel",
	"Identify and exploit vulnerabilities",
	"Identify and select appropriate pedagogical approaches for the intended audience",
	"Identify and solve cybersecurity-related issues",
	"Identify needs in cybersecurity awareness, training and education",
	"Identify non-cyber events with implications on cyber-related activities",
	"Identify threat actors TTPs and campaigns",
	"Identify, analyse and correlate cybersecurity events",
	"Implement cybersecurity recommendations and best practices",
	"Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
	"Influence an organisation’s cybersecurity culture",
	"Integrate cybersecurity solutions to the organisation’s infrastructure",
	"Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties",
	"Manage and analyse log files",
	"Manage cybersecurity resources",
	"Model threats, actors and TTPs",
	"Monitor new advancements in cybersecurity-related technologies",
	"Motivate and encourage people",
	"Organise and work in a systematic and deterministic way based on evidence",
	"Perform social engineering",
	"Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
	"Propose and manage risk-sharing options",
	"Propose cybersecurity architectures based on stakeholder’s needs and budget",
	"Provide training towards cybersecurity and data protection professional certifications",
	"Review and enhance security documents, reports, SLAs and ensure the security objectives",
	"Review codes assess their security",
	"Select appropriate specifications, procedures and controls",
	"Think creatively and outside the box",
	"Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies",
	"Understand, practice and adhere to ethical requirements and standards",
	"Use and apply CTI platforms and tools",
	"Use penetration testing tools effectively",
	"Utilise existing cybersecurity-related training resources",
	"Work ethically and independently; not influenced and biased by internal or external actors",
	"Work on operating systems, servers, clouds and relevant infrastructures",
	"Work under pressure"
]

export default ecsfSkills
