import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarningIcon from '@mui/icons-material/Warning';

import areas from './initStructure/SpartaAreas'
import topics from './initStructure/SpartaTopics'

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDragDisabled
      ? 'lightgrey'
      : props.isDragging
        ? 'lightgreen'
        : 'white'};
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`

class Course extends React.Component {

  render() {
    const course = this.props.course
    const isDragDisabled = course.id === 'task-1'

    const showTopics = course.topics.map(el => {
      return topics[el.topicId].name
    })

    const showAreas = [...new Set(course.topics.map(el => {
      return areas[topics[el.topicId].areaId].name
    }))]

    const percentChecksum = () => {
      let array = [...course.ecsfSkillIds,...course.ecsfKnowledgeIds]
      return round((Object.keys(array).reduce((sum,key)=>sum+parseFloat(array[key].ectsPer||0),0))*100,2)
    }

    const round = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision)

    const isChecksumValid = () => {
      if ([...course.ecsfKnowledgeIds, ...course.ecsfSkillIds].length) {
        let checksum = percentChecksum([...course.ecsfKnowledgeIds, ...course.ecsfSkillIds])
        if (checksum > 100) {
          return 0
        } else if (course.nonEcsfItems) {
          return 1
        } else if (checksum === 100) {
          return 2
        } else {
          return 0
        }
      } else {
        return 1
      }
    }


    return (
      <Draggable
        draggableId={course.id}
        index={this.props.index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
          >
            <div className="course">
              <div className="rightBtn" title={isNaN(this.props.columnId.charAt(0)) ? "Permanently remove" : "Move back to the course list"} onClick={()=>this.props.removeCourse({
                id: course.id,
                type: course.type,
                semester: course.semester,
                position: this.props.columnId
              })}>{isNaN(this.props.columnId.charAt(0)) ? <DeleteForeverIcon color="action"/> : <ClearIcon color="action"/>}</div>
              <div className="middleBtn" title="Edit" onClick={()=>this.props.openAddModal(course.id, this.props.columnId)}>
                <EditIcon color="action"/>
              </div>
              {isChecksumValid() === 0 ? (
                <div className="leftBtn" title="Wrong parameters" >
                  <WarningIcon color="action"/>
                </div>
              ) : null }
              <div className="semester">{course.semester} {course.type} course
              </div>
      		    <div className="title">{course.name}</div>
              <div className="area_topics">
                {showAreas.map((el, index) => (
                  <font key={index}>{el}{index !== (showAreas.length -1) ? " / " : ""}</font>
                ))}
                <table><tbody>
                  {showTopics.map((el, index) => (
                    <tr key={index}><td>•&nbsp;</td><td>{el}</td></tr>
                  ))}
                </tbody></table>
              </div>
            {/*<div className="credits">ECTS {course.credits}</div>*/}
            </div>
          </Container>
        )}
      </Draggable>
    )
  }
}

export default Course
