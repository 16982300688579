const ecsfKnowledge = [
  "Advanced and persistent cyber threats (APT)",
	"Auditing standards, methodologies and frameworks",
	"Auditing-related certification",
	"Computer networks security",
	"Computer programming",
	"Computer Security Incident Response Teams (CSIRTs) operation",
	"Computer systems vulnerabilities",
	"Conformity assessment standards, methodologies and frameworks",
	"Criminal investigation procedures, standards, methodologies and frameworks",
	"Cross-domain and border-domain knowledge related to cybersecurity",
	"Cyber threat actors",
	"Cyber Threat Intelligence (CTI) sharing standards, methodologies and frameworks",
	"Cyber threats",
	"Cybersecurity attack procedures",
	"Cybersecurity awareness, education and training programme development",
	"Cybersecurity controls and solutions",
	"Cybersecurity education and training standards, methodologies and frameworks",
	"Cybersecurity maturity models",
	"Cybersecurity policies",
	"Cybersecurity procedures",
	"Cybersecurity recommendations and best practices",
	"Cybersecurity related laws, regulations and legislations",
	"Cybersecurity risks",
	"Cybersecurity standards, methodologies and frameworks",
	"Cybersecurity trends",
	"Cybersecurity-related certifications",
	"Cybersecurity-related requirements analysis",
	"Cybersecurity-related research, development and innovation (RDI)",
	"Cybersecurity-related technologies",
	"Digital forensics analysis procedures",
	"Digital forensics recommendations and best practices",
	"Digital forensics standards, methodologies and frameworks",
	"Ethical cybersecurity organisation requirements",
	"Incident handling communication procedures",
	"Incident handling recommendations and best practices",
	"Incident handling standards, methodologies and frameworks",
	"Incident handling tools",
	"Information technology (IT) and operational technology (OT) appliances",
	"Legacy cybersecurity procedures",
	"Legal, regulatory and legislative compliance requirements, recommendations and best practices",
	"Legal, regulatory and legislative requirements on releasing or using cybersecurity related technologies",
	"Malware analysis tools",
	"Management practices",
	"Monitoring, testing and evaluating cybersecurity controls' effectiveness",
	"Multidiscipline aspect of cybersecurity",
	"Offensive and defensive security practices",
	"Offensive and defensive security procedures",
	"Operating systems security",
	"Pedagogical standards, methodologies and frameworks",
	"Penetration testing procedures",
	"Penetration testing standards, methodologies and frameworks",
	"Penetration testing tools",
	"Privacy impact assessment standards, methodologies and frameworks",
	"Privacy-by-design standards, methodologies and frameworks",
	"Privacy-Enhancing Technologies (PET)",
	"Resource management",
	"Responsible information disclosure procedures",
	"Risk management recommendations and best practices",
	"Risk management standards, methodologies and frameworks",
	"Risk management tools",
	"Secure coding recommendations and best practices",
	"Secure development lifecycle",
	"Secure Operation Centres (SOCs) operation",
	"Security architecture reference models",
	"Testing procedures",
	"Testing standards, methodologies and frameworks",
	"Threat actors Tactics, Techniques and Procedures (TTPs)"
]

export default ecsfKnowledge
