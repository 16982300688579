import React from 'react'
import { Link} from 'react-router-dom'
import {Config} from '../config.js'
import Axios from 'axios'
import {Alert, Col, Container, Row, Spinner, Table} from 'react-bootstrap'
import Chart from "react-apexcharts"


class VisitorStats extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      error: null,
      data: []
    }
  }

  componentDidMount() {
    this.getStats()
  }

  getStats = () => {
    Axios.get( Config.server.visitorStats)
      .then((response) => {
        if (!response.data.error){
          let output = {
            yes: response.data.data.filter(item => item.feedback === 'useful').length,
            no: response.data.data.filter(item => item.feedback === 'useless').length,
            unknown: response.data.data.filter(item => item.feedback === '').length
          }
          this.setState({isLoading: false, data: output})
        } else {
          this.setState({isLoading: false, error:{message: "Sorry, something went wrong. :-("}})
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({isLoading: false, error:{message: "Sorry, something went wrong. :-("}})
      })
  }


  render(){
    const { error, isLoading, data} = this.state

    const pieChart = {
      series:  [data.yes, data.no, data.unknown],
      options: {
        legend: {
          position: 'bottom'
        },
        colors: ['rgb(0, 227, 150)', 'rgb(255, 69, 96)', 'rgb(254, 176, 25)'],

        labels: ["Yes", "No", "Without an Answer"],
      }
    }

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <br/><Link to={"/"}>Go back</Link>
          </div>
        ) : (
          isLoading ? (
            <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading</div>
          ) : (
            <div className="visitor-stats">
              <h3 className="section-title">Visitors Statistics</h3>
              <div className="content">
                <div className="content-scroll">

                  <h5>Is this application useful?</h5>

                  <Container>
                    <Row>
                      <Col xs={6} className="vertical-center">
                        <Table bordered hover >
                          <thead>
                            <tr>
                              <th>Answer</th>
                              <th>Number</th>
                              <th>Percent</th>
                            </tr>
                          </thead>
                          <tbody>
                             <tr>
                              <td>Yes</td>
                              <td>{data.yes}</td>
                              <td>{Math.round((data.yes * 100 / (data.yes + data.no + data.unknown)) * 10) / 10} %</td>
                            </tr>
                            <tr>
                             <td>No</td>
                             <td>{data.no}</td>
                             <td>{Math.round((data.no * 100 / (data.yes + data.no + data.unknown)) * 10) / 10} %</td>
                           </tr>
                           <tr>
                            <td>Without an answer</td>
                            <td>{data.unknown}</td>
                            <td>{Math.round((data.unknown * 100 / (data.yes + data.no + data.unknown)) * 10) / 10} %</td>
                          </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={6} className="vertical-center">
                        <Chart
                          options={pieChart.options}
                          series={pieChart.series}
                          type="pie"
                          className="chart-container"
                        />
                      </Col>

                    </Row>
                  </Container>

                </div>
              </div>
            </div>
          )
        )}
      </div>
    )
  }

}

export default VisitorStats;
