import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import './assets/style.scss';
import { Config } from './config.js';
import App from './components/App';
import VisitorStats from './components/VisitorStats';
import NotFound from './components/NotFound';
import logo from '../src/assets/icon_curricula_m.png';
import logo_sparta from '../src/assets/logo_sparta.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <header>
        <div className="App-header">
          <div className="logo-left">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Link to={Config.appRoot}>
                      <img className="logo-left-img" src={logo} alt="logo-app" />
                    </Link>
                  </td>
                  <td className="how-to">
                    <a href={Config.appRoot + "files/DesignerHowTo.pdf"} target="_blank" rel="noopener noreferrer">
                      <HelpOutlineIcon /> Help
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h1>Cybersecurity Curricula Designer</h1>
          <div className="logo-right">
            <a href="https://www.sparta.eu/">
              <img className="logo-right-img" src={logo_sparta} alt="logo" />
            </a>
          </div>
        </div>
      </header>
      <div className="App">
        <Switch>
          <Route component={App} exact path={Config.appRoot} />
          <Route component={VisitorStats} path={Config.appRoot + "visitor-stats"} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <footer>
        <div className="App-footer">
          <p>This project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement No 830892.</p>
        </div>
      </footer>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();