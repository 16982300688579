const sampleCourses = [
    {
        "name": "Communication Technologies",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 3, "ectsPer": 0.5},
            {"topicId": 12, "ectsPer": 0.5}
        ],
        "ecsfSkillIds": [
          {"skillId": 20, "ectsPer": 0.05},
          {"skillId": 33, "ectsPer": 0.05},
          {"skillId": 37, "ectsPer": 0.05},
          {"skillId": 38, "ectsPer": 0.05},
          {"skillId": 49, "ectsPer": 0.05},
          {"skillId": 50, "ectsPer": 0.05},
          {"skillId": 51, "ectsPer": 0.05},
          {"skillId": 64, "ectsPer": 0.05},
          {"skillId": 70, "ectsPer": 0.05},
          {"skillId": 79, "ectsPer": 0.05}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 14, "ectsPer": 0.0625},
          {"knowledgeId": 15, "ectsPer": 0.0625},
          {"knowledgeId": 16, "ectsPer": 0.0625},
          {"knowledgeId": 20, "ectsPer": 0.0625},
          {"knowledgeId": 21, "ectsPer": 0.0625},
          {"knowledgeId": 23, "ectsPer": 0.0625},
          {"knowledgeId": 25, "ectsPer": 0.0625},
          {"knowledgeId": 48, "ectsPer": 0.0625}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Computers and Programming 1",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 0, "ectsPer": 0.5},
            {"topicId": 6, "ectsPer": 0.5}
        ],
        "ecsfSkillIds": [
          {"skillId": 12, "ectsPer": 0.05},
          {"skillId": 20, "ectsPer": 0.05},
          {"skillId": 24, "ectsPer": 0.05},
          {"skillId": 27, "ectsPer": 0.05},
          {"skillId": 28, "ectsPer": 0.05},
          {"skillId": 31, "ectsPer": 0.05},
          {"skillId": 40, "ectsPer": 0.05},
          {"skillId": 47, "ectsPer": 0.05},
          {"skillId": 69, "ectsPer": 0.05},
          {"skillId": 73, "ectsPer": 0.05}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 12, "ectsPer": 0.034},
          {"knowledgeId": 15, "ectsPer": 0.034},
          {"knowledgeId": 20, "ectsPer": 0.034},
          {"knowledgeId": 22, "ectsPer": 0.034},
          {"knowledgeId": 23, "ectsPer": 0.034},
          {"knowledgeId": 24, "ectsPer": 0.033},
          {"knowledgeId": 25, "ectsPer": 0.033},
          {"knowledgeId": 26, "ectsPer": 0.033},
          {"knowledgeId": 28, "ectsPer": 0.033},
          {"knowledgeId": 38, "ectsPer": 0.033},
          {"knowledgeId": 39, "ectsPer": 0.033},
          {"knowledgeId": 53, "ectsPer": 0.033},
          {"knowledgeId": 54, "ectsPer": 0.033},
          {"knowledgeId": 61, "ectsPer": 0.033},
          {"knowledgeId": 63, "ectsPer": 0.033}
        ],
        "credits": 5,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Computers and Programming 2",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 6,"ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 8, "ectsPer": 0.0715},
          {"skillId": 14, "ectsPer": 0.0715},
          {"skillId": 20, "ectsPer": 0.0715},
          {"skillId": 26, "ectsPer": 0.0715},
          {"skillId": 35, "ectsPer": 0.0715},
          {"skillId": 50, "ectsPer": 0.0715},
          {"skillId": 58, "ectsPer": 0.0715}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 3, "ectsPer": 0.0455},
          {"knowledgeId": 4, "ectsPer": 0.0454},
          {"knowledgeId": 15, "ectsPer": 0.0454},
          {"knowledgeId": 20, "ectsPer": 0.0454},
          {"knowledgeId": 28, "ectsPer": 0.0454},
          {"knowledgeId": 45, "ectsPer": 0.0454},
          {"knowledgeId": 47, "ectsPer": 0.0454},
          {"knowledgeId": 60, "ectsPer": 0.0454},
          {"knowledgeId": 61, "ectsPer": 0.0454},
          {"knowledgeId": 64, "ectsPer": 0.0454},
          {"knowledgeId": 65, "ectsPer": 0.0454}
        ],
        "credits": 5,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Course 1",
        "semester": "winter",
        "type": "voluntary",
        "topics": [
            {"topicId": 27, "ectsPer": 1}
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "credits": 9,
        "nonEcsfItems": false,
        "training": "No"
    },
    {
        "name": "Course 2",
        "semester": "winter",
        "type": "voluntary",
        "topics": [
            {"topicId": 28, "ectsPer": 1}
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "credits": 9,
        "nonEcsfItems": false,
        "training": "No"
    },
    {
        "name": "Course 3",
        "semester": "summer",
        "type": "voluntary",
        "topics": [
            {"topicId": 11, "ectsPer": 1}
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "credits": 5,
        "nonEcsfItems": false,
        "training": "No"
    },
    {
        "name": "Course 4",
        "semester": "summer",
        "type": "voluntary",
        "topics": [
            {"topicId": 25, "ectsPer": 1}
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Data Communication",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 2, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 15, "ectsPer": 0.1},
          {"skillId": 34, "ectsPer": 0.1},
          {"skillId": 44, "ectsPer": 0.1},
          {"skillId": 54, "ectsPer": 0.1},
          {"skillId": 80, "ectsPer": 0.1}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 3, "ectsPer": 0.0385},
          {"knowledgeId": 6, "ectsPer": 0.0385},
          {"knowledgeId": 8, "ectsPer": 0.0385},
          {"knowledgeId": 12, "ectsPer": 0.0385},
          {"knowledgeId": 13, "ectsPer": 0.0385},
          {"knowledgeId": 21, "ectsPer": 0.0385},
          {"knowledgeId": 25, "ectsPer": 0.0385},
          {"knowledgeId": 29, "ectsPer": 0.0385},
          {"knowledgeId": 30, "ectsPer": 0.0384},
          {"knowledgeId": 31, "ectsPer": 0.0384},
          {"knowledgeId": 41, "ectsPer": 0.0384},
          {"knowledgeId": 47, "ectsPer": 0.0384},
          {"knowledgeId": 64, "ectsPer": 0.0384}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Discrete Mathematics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 16, "ectsPer": 0.5},
            {"topicId": 18, "ectsPer": 0.5}
        ],
        "ecsfSkillIds": [
          {"skillId": 14, "ectsPer": 0.125},
          {"skillId": 20, "ectsPer": 0.125},
          {"skillId": 28, "ectsPer": 0.125},
          {"skillId": 29, "ectsPer": 0.125}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 23, "ectsPer": 0.1665},
          {"knowledgeId": 27, "ectsPer": 0.1665},
          {"knowledgeId": 40, "ectsPer": 0.167}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Foundations of Cryptography",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 9,"ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 10, "ectsPer": 0.05},
          {"skillId": 14, "ectsPer": 0.05},
          {"skillId": 16, "ectsPer": 0.05},
          {"skillId": 18, "ectsPer": 0.05},
          {"skillId": 20, "ectsPer": 0.05},
          {"skillId": 23, "ectsPer": 0.05},
          {"skillId": 52, "ectsPer": 0.05},
          {"skillId": 53, "ectsPer": 0.05},
          {"skillId": 62, "ectsPer": 0.05},
          {"skillId": 77, "ectsPer": 0.05}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 0, "ectsPer": 0.0385},
          {"knowledgeId": 3, "ectsPer": 0.0385},
          {"knowledgeId": 4, "ectsPer": 0.0385},
          {"knowledgeId": 9, "ectsPer": 0.0385},
          {"knowledgeId": 10, "ectsPer": 0.0385},
          {"knowledgeId": 11, "ectsPer": 0.0385},
          {"knowledgeId": 12, "ectsPer": 0.0385},
          {"knowledgeId": 13, "ectsPer": 0.0385},
          {"knowledgeId": 15, "ectsPer": 0.0384},
          {"knowledgeId": 25, "ectsPer": 0.0384},
          {"knowledgeId": 47, "ectsPer": 0.0384},
          {"knowledgeId": 56, "ectsPer": 0.0384},
          {"knowledgeId": 66, "ectsPer": 0.0384}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Incidents",
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes",
        "topics": [
            {"topicId": 15, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 16, "ectsPer": 0.096},
          {"skillId": 60, "ectsPer": 0.096},
          {"skillId": 67, "ectsPer": 0.096},
          {"skillId": 81, "ectsPer": 0.096},
          {"skillId": 82, "ectsPer": 0.096}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 3, "ectsPer": 0.04},
          {"knowledgeId": 5, "ectsPer": 0.04},
          {"knowledgeId": 6, "ectsPer": 0.04},
          {"knowledgeId": 12, "ectsPer": 0.04},
          {"knowledgeId": 13, "ectsPer": 0.04},
          {"knowledgeId": 21, "ectsPer": 0.04},
          {"knowledgeId": 25, "ectsPer": 0.04},
          {"knowledgeId": 33, "ectsPer": 0.04},
          {"knowledgeId": 34, "ectsPer": 0.04},
          {"knowledgeId": 35, "ectsPer": 0.04},
          {"knowledgeId": 36, "ectsPer": 0.04},
          {"knowledgeId": 47, "ectsPer": 0.04},
          {"knowledgeId": 62, "ectsPer": 0.04}
        ],
        "semester": "summer",
        "type": "mandatory"
    },
    {
        "name": "Legal Theory",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 27, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 13, "ectsPer": 0.0625},
          {"skillId": 14, "ectsPer": 0.0625},
          {"skillId": 21, "ectsPer": 0.0625},
          {"skillId": 25, "ectsPer": 0.0625},
          {"skillId": 43, "ectsPer": 0.0625},
          {"skillId": 59, "ectsPer": 0.0625},
          {"skillId": 75, "ectsPer": 0.0625},
          {"skillId": 76, "ectsPer": 0.0625}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 18, "ectsPer": 0.1},
          {"knowledgeId": 21, "ectsPer": 0.1},
          {"knowledgeId": 23, "ectsPer": 0.1},
          {"knowledgeId": 39, "ectsPer": 0.1},
          {"knowledgeId": 52, "ectsPer": 0.1}
        ],
        "credits": 7,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Physics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 21, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 46, "ectsPer": 0.20},
          {"skillId": 50, "ectsPer": 0.20},
          {"skillId": 63, "ectsPer": 0.20}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 44, "ectsPer": 0.20},
          {"knowledgeId": 56, "ectsPer": 0.20}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Multimedia Services",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 0, "ectsPer": 0.5},
            {"topicId": 12, "ectsPer": 0.5}
        ],
        "ecsfSkillIds": [
          {"skillId": 55, "ectsPer": 0.1},
          {"skillId": 57, "ectsPer": 0.1},
          {"skillId": 61, "ectsPer": 0.1},
          {"skillId": 64, "ectsPer": 0.1},
          {"skillId": 71, "ectsPer": 0.1}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 55, "ectsPer": 0.25},
          {"knowledgeId": 58, "ectsPer": 0.25}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Mathematics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 20, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 32, "ectsPer": 0.125},
          {"skillId": 39, "ectsPer": 0.125},
          {"skillId": 42, "ectsPer": 0.125},
          {"skillId": 50, "ectsPer": 0.125}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 25, "ectsPer": 0.167},
          {"knowledgeId": 32, "ectsPer": 0.167},
          {"knowledgeId": 42, "ectsPer": 0.166}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Privacy",
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes",
        "topics": [
            {"topicId": 22, "ectsPer": 0.5},
            {"topicId": 24, "ectsPer": 0.5}
        ],
        "ecsfSkillIds": [
          {"skillId": 3, "ectsPer": 0.0625},
          {"skillId": 6, "ectsPer": 0.0625},
          {"skillId": 9, "ectsPer": 0.0625},
          {"skillId": 17, "ectsPer": 0.0625},
          {"skillId": 19, "ectsPer": 0.0625},
          {"skillId": 29, "ectsPer": 0.0625},
          {"skillId": 45, "ectsPer": 0.0625},
          {"skillId": 65, "ectsPer": 0.0625}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 1, "ectsPer": 0.0625},
          {"knowledgeId": 2, "ectsPer": 0.0625},
          {"knowledgeId": 7, "ectsPer": 0.0625},
          {"knowledgeId": 15, "ectsPer": 0.0625},
          {"knowledgeId": 23, "ectsPer": 0.0625},
          {"knowledgeId": 25, "ectsPer": 0.0625},
          {"knowledgeId": 39, "ectsPer": 0.0625},
          {"knowledgeId": 43, "ectsPer": 0.0625}
        ],
        "semester": "winter",
        "type": "mandatory"
    },
    {
        "name": "Probability and Statistics",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {"topicId": 19, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 1, "ectsPer": 0.0835},
          {"skillId": 11, "ectsPer": 0.0835},
          {"skillId": 20, "ectsPer": 0.0835},
          {"skillId": 41, "ectsPer": 0.0835},
          {"skillId": 56, "ectsPer": 0.0835},
          {"skillId": 68, "ectsPer": 0.0825}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 6, "ectsPer": 0.05},
          {"knowledgeId": 12, "ectsPer": 0.05},
          {"knowledgeId": 15, "ectsPer": 0.05},
          {"knowledgeId": 22, "ectsPer": 0.05},
          {"knowledgeId": 25, "ectsPer": 0.05},
          {"knowledgeId": 28, "ectsPer": 0.05},
          {"knowledgeId": 43, "ectsPer": 0.05},
          {"knowledgeId": 57, "ectsPer": 0.05},
          {"knowledgeId": 58, "ectsPer": 0.05},
          {"knowledgeId": 59, "ectsPer": 0.05}
        ],
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes"
    },
    {
        "name": "Testing",
        "credits": 6,
        "nonEcsfItems": false,
        "training": "Yes",
        "topics": [
            {"topicId": 14, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 20, "ectsPer": 0.0455},
          {"skillId": 22, "ectsPer": 0.0455},
          {"skillId": 23, "ectsPer": 0.0455},
          {"skillId": 29, "ectsPer": 0.0455},
          {"skillId": 46, "ectsPer": 0.0455},
          {"skillId": 48, "ectsPer": 0.0455},
          {"skillId": 50, "ectsPer": 0.0455},
          {"skillId": 66, "ectsPer": 0.0455},
          {"skillId": 72, "ectsPer": 0.0455},
          {"skillId": 74, "ectsPer": 0.0455},
          {"skillId": 78, "ectsPer": 0.0455}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 3, "ectsPer": 0.042},
          {"knowledgeId": 4, "ectsPer": 0.042},
          {"knowledgeId": 6, "ectsPer": 0.042},
          {"knowledgeId": 13, "ectsPer": 0.0415},
          {"knowledgeId": 20, "ectsPer": 0.0415},
          {"knowledgeId": 25, "ectsPer": 0.0415},
          {"knowledgeId": 37, "ectsPer": 0.0415},
          {"knowledgeId": 46, "ectsPer": 0.0415},
          {"knowledgeId": 47, "ectsPer": 0.0415},
          {"knowledgeId": 49, "ectsPer": 0.0415},
          {"knowledgeId": 50, "ectsPer": 0.0415},
          {"knowledgeId": 51, "ectsPer": 0.0415}
        ],
        "semester": "winter",
        "type": "mandatory"
    },
    {
        "name": "Theoretical Informatics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {"topicId": 5, "ectsPer": 1}
        ],
        "ecsfSkillIds": [
          {"skillId": 0, "ectsPer": 0.0718},
          {"skillId": 2, "ectsPer": 0.0717},
          {"skillId": 4, "ectsPer": 0.0717},
          {"skillId": 5, "ectsPer": 0.0717},
          {"skillId": 7, "ectsPer": 0.0717},
          {"skillId": 18, "ectsPer": 0.0717},
          {"skillId": 30, "ectsPer": 0.0717}
        ],
        "ecsfKnowledgeIds": [
          {"knowledgeId": 17, "ectsPer": 0.083},
          {"knowledgeId": 18, "ectsPer": 0.083},
          {"knowledgeId": 19, "ectsPer": 0.083},
          {"knowledgeId": 20, "ectsPer": 0.083},
          {"knowledgeId": 21, "ectsPer": 0.083},
          {"knowledgeId": 23, "ectsPer": 0.083}
        ],
        "credits": 7,
        "nonEcsfItems": false,
        "training": "Yes"
    }
]

export default sampleCourses
